.table {
    font-size: 13px;

    td,
    th {
        white-space: pre-wrap;
    }

    th {
        white-space: nowrap;
    }

    & > tbody > tr > td {
        vertical-align: middle;
    }
}
