.config-page {
  //  display: flex;
  //  flex-direction: column;
  //  align-items: flex-start;
  //  padding: 20px;
  //  height: 100vh;
}
.candle-chart{
    width: 100%;
    height: 500px;
}