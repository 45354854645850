.symbol-row {
    .action {
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
    }

    &__coin {
        font-size: 14px;
        font-weight: bold;
        color: #ca9003;
    }
}
