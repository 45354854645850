.cell {
    &--up-down {
        transition: 0.3s ease;
    }

    &--up {
        color: #2e7d32;
    }

    &--down {
        color: #c62828;
    }

    & *[contenteditable] {
        cursor: pointer;
    }

    & *[contenteditable]:focus {
        cursor: text;
        outline: 1px dashed #ca9003;
        outline-offset: 3px;
    }
}
