.coming-soon-box {
  padding: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.chart-main{
  min-width: 900px;
  width: 100%;
  height: 540px;


}